import { useState, useEffect } from "react";
import like_icon_default from "../manorama/assets/images/like_icon_default.png";
import like_icon_highlighted from "../manorama/assets/images/like_icon_selected.png";
import share_icon_default from "../manorama/assets/images/share_icon_default.png";
import myplexAPI from "../manorama/api/myplexAPI";
import {
    FacebookShareButton,
    // TwitterShareButton,
    // TwitterIcon,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
const Sharelike = ({item}) => {
    const [favIcon, setFavIcon] = useState(share_icon_default);
    const [favStatus, setFavStatus] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [shareMenuOpen, setShareMenuOpen] = useState(false);
    const getFavIcon = (item) => {
        if(item.currentUserData.favorite){
            setFavIcon(like_icon_highlighted);
            setFavStatus(true);
        }else{
            setFavIcon(like_icon_default);
            setFavStatus(false);
        }
        setLikeCount(item?.stats?.favoriteCount || 0);
    }
    const favoriteIconClick = (status,item) => {
        if(localStorage.getItem("Logged-in") !== undefined && localStorage.getItem("Logged-in") !== null){
            myplexAPI
            .watchliststate(item._id, status, item.generalInfo.type)
            .then((response) => {
                // console.log(response, "watchlist api");
                if (response.data.code === 201 || response.data.code === 200) {
                    if (response.data.favorite === "true") {
                        setFavIcon(like_icon_highlighted);
                        setLikeCount(parseInt(likeCount) + 1);
                    } else {
                        setFavIcon(like_icon_default);
                        setLikeCount(parseInt(likeCount) - 1)
                    }
                } else {
                    localStorage.setItem(
                        "redirectUrl",window.location.pathname
                    );
                    getSsoClientId();
                }
            });
            setFavStatus(status);
        }else{
            localStorage.setItem(
                "redirectUrl",window.location.pathname
            );
            getSsoClientId();
        }
    }
    const getSsoClientId = () => {
        myplexAPI.getSsoAppInfo().then((response) => {
          // console.log(response)
          if (response.data.code === 200 && response.data.status === "SUCCESS") {
            localStorage.setItem("client_id", response.data.app_id);
            // window.location.href = `https://id.manoramaonline.com/authorize?scope=openid+email+profile+offline_access+mobile&response_type=code+token+id_token&client_id=${response.data.app_id}&redirect_uri=${process.env.REACT_APP_DOMAINNAME}login&state=1135778640&nonce=212883085`;
            window.location.href = `${process.env.REACT_APP_SSO_URL}authorize?scope=openid+email+mobile+profile+offline_access&response_type=code&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DOMAINNAME}login&state=156414&nonce=177292&display=&prompt=`
          }
        });
    };
    useEffect(() => {
        getFavIcon(item);
    },[item]);
    const isLogin = () => {
        return localStorage.getItem("Logged-in") !== undefined && localStorage.getItem("Logged-in") !== null ? true : false;
    }
    return (
        <div className="shareLike">
            <ul className="shareLikeUl">
            { isLogin() &&
                <li>
                    <img
                        src={favIcon}
                        alt="like"
                        className="shorts_favorites_icon"
                        onClick={(e)=>favoriteIconClick(!favStatus,item)}
                    />
                    <p>{likeCount}</p>
                </li>
            }
            <li>
                <img
                    src={share_icon_default}
                    alt="share"
                    className="shorts_favorites_icon"
                    onClick={()=>{setShareMenuOpen(!shareMenuOpen)}}
                />
                <p>Share</p>
                {shareMenuOpen && 
                    <div className="share_icons_list">
                        <ul>
                            <li><FacebookShareButton
                                title={"watch " + item.generalInfo.title}
                                text={item.generalInfo.description}
                                url={window.location.origin.replace(/^http:\/\//i, 'https://')+'/minis/'+item.generalInfo._id}
                                img={share_icon_default}
                                >
                                <FacebookIcon size={40} round />
                                </FacebookShareButton>
                            </li>
                            {/* <li><TwitterShareButton
                                title={"watch " + item.generalInfo.title}
                                text={item.generalInfo.description}
                                url={window.location.origin.replace(/^http:\/\//i, 'https://')+'/reels/'+item.generalInfo._id}
                                >
                                <TwitterIcon size={40} round />

                                </TwitterShareButton>
                            </li> */}
                            <li><WhatsappShareButton
                                title={"watch " + item.generalInfo.title}
                                text={item.generalInfo.description}
                                url={window.location.origin.replace(/^http:\/\//i, 'https://')+'/minis/'+item.generalInfo._id}
                                >
                                <WhatsappIcon size={40} round />

                                </WhatsappShareButton>
                            </li>
                        </ul>
                    </div>
                }
            </li>
            </ul>

        </div>
    );
}
export default Sharelike;