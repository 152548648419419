import React from "react";
// import home_default_icon from "../../assets/images/home_default_icon.png";
// import home_highlighted_icon from "../../assets/images/home_highlighted_icon.png";
import My_Stuff_icon_mm from "../../assets/images/My_Stuff_icon_mm.png";
// import tv_shows_default_icon from "../../assets/images/tv_shows_default_icon.png";
// import tv_shows_highlighted_icon from "../../assets/images/tv_shows_highlighted_icon.png";
// import kids_default_icon from "../../assets/images/kids_default_icon.png";
// import kids_highlighted_icon from "../../assets/images/kids_highlighted_icon.png";
// import music_default_icon from "../../assets/images/music_default_icon.png";
// import music_highlighted_icon from "../../assets/images/music_highlighted_icon.png";
// import live_default_icon from "../../assets/images/live_default_icon.png";
// import live_highlighted_icon from "../../assets/images/live_highlighted_icon.png";
// import {isMobile} from "react-device-detect";
import about_icon_mm from "../../assets/images/about_icon_mm.png";
import about_default_icon from "../../assets/images/about_default_icon.png";
import about_highlighted_icon from "../../assets/images/about_highlighted_icon.png";
// import help_default_icon from "../../assets/images/help_default_icon.png";
// import help_highlighted_icon from "../../assets/images/help_highlighted_icon.png";
// import language_default_icon from "../../assets/images/language_default_icon.png";
// import language_highlight_icon from "../../assets/images/language_highlight_icon.png";
// import app_language_icon_default_icon from "../../assets/images/app_language_icon_default_icon.png";
// import app_language_icon_highlight_icon from "../../assets/images/app_language_icon_highlight_icon.png";
import nav_drawer_language_icon_default from "../../assets/images/nav_drawer_language_icon_default.png";
import nav_drawer_language_icon_highlight from "../../assets/images/nav_drawer_language_icon_highlight.png";
import nav_drawer_my_devices_icon_default from "../../assets/images/nav_drawer_my_devices_icon.png";
import nav_drawer_my_devices_icon_highlight from "../../assets/images/nav_drawer_my_devices_icon.png";
import { isMobile } from "react-device-detect";
import axios from "axios";

//import about_icon from "../../assets/images/about_apex_sidemenu.png"

// import nav_drawer_watchlist_icon_default from "../../assets/images/footer_tab_watchlist_default_apex.png";
// import nav_drawer_watchlist_icon_highlight from "../../assets/images/footer_tab_watchlist_selected_apex.png";

// import my_stuff_default from "../../assets/images/footer_tab_my_stuff_default_apex.png"
// import my_stuff_highlight from "../../assets/images/footer_tab_my_stuff_selected_apex.png"

import nav_drawer_my_Stuff_icon_apex from "../../assets/images/nav_drawer_my_Stuff_icon_apex_new.png";
import nav_drawer_help_icon_apex from "../../assets/images/nav_drawer_help_icon_apex_now.png";
import nav_drawer_watchlist_icon_apex from "../../assets/images/nav_drawer_my_Watchlist_icon_apex_new.png";
//import Menu_MMTV from "../../assets/images/Menu_manorama_Max.png"

// import home_default from "../../assets/images/footer_tab_home_default_apex.png";
//import home_selected from "../../assets/images/home_apex_new.png";
import world_default from "../../assets/images/world_default.png";
import world_selected from "../../assets/images/world_selected.png";
//import originals_default from "../../assets/images/originals_default.png";
//import originals_selected from "../../assets/images/originals_selected.png";
import menu_close from "../../assets/images/nav_drawer_close_icon.png";
import brand_icon from "../../assets/images/nav_drawer_brand_icon.png";
import myaccount_profile_icon from "../../assets/images/account_profile_thumbnail.png";
import right_arrow from "../../assets/images/nav_drawer_option_arrow_grey.png";
import right_arrow_highlighted from "../../assets/images/nav_drawer_option_arrow_white.png";
import downloads_icon from "../../assets/images/nav_drawer_downloads_icon.png";
import downloads_icon_highlighted from "../../assets/images/nav_drawer_downloads_icon_highlighted.png";
import favorites_icon from "../../assets/images/nav_drawer_favorites_icon.png";
import favorites_icon_highlighted from "../../assets/images/nav_drawer_favorites_icon_highlighted.png";
import watch_history_icon from "../../assets/images/nav_drawer_watch_history_icon.png";
import watch_history_icon_highlighted from "../../assets/images/nav_drawer_watch_history_icon_highlighted.png";
import playlist_icon from "../../assets/images/nav_drawer_playlist_icon.png";
import playlist_icon_highlighted from "../../assets/images/nav_drawer_playlist_icon_highlighted.png";
import premium_icon from "../../assets/images/nav_drawer_premium_icon.png";
import premium_icon_highlighted from "../../assets/images/nav_drawer_premium_icon_highlighted.png";
import myplexAPI from "../../api/myplexAPI";

// import help_default from "../../assets/images/help_default.png";
// import help_selected from "../../assets/images/help_selected.png";

import { NavLink } from "react-router-dom";
//import { Nav } from "react-bootstrap";

const premiumRedirect = (actionUrl,p) => {
  var actUrl = actionUrl + `&redirectUrl=${window.location}`;
  axios({
    method: "get",
    url: actUrl,
  }).then((res) => {
    if ((res.data.success = "SUCCESS")) {
      if (res.data.ui) {
        var pUrl = res.data.ui.redirect + `&redirectUrl=${window.location}`;
        // if(localStorage.getItem("ProfileUpdate")=="true"){
        //   localStorage.setItem(
        //     "redirectUrl",pUrl
        //   );
        //   p.history.push("/editprofile");
       
        // }else {

          window.location.href = pUrl;
        // }
        
      }
    }
  });
};
const _fetchMenu = (props) => {
  let indexArrayNav = props.data.findIndex((item, i) => {
    return item.layoutType === "sideNavMenuSeparator";
  });

  // var slicecount;
  // eslint-disable-next-line
  // {isMobile ?
  //  slicecount = 5 : slicecount = 0}
  // eslint-disable-next-line array-callback-return
// console.log(props.data);
  return props.data
    .slice(0, indexArrayNav)
    .filter((item, i) => {
      if (item.name === "premium_Pwa" || item.name === "portalPremium") {
        localStorage.setItem("premiumUrl", item.actionUrl);
      // let pack = sessionStorage.getItem("userPacks")&& JSON.parse(sessionStorage.getItem("userPacks")).length>0 && JSON.parse(sessionStorage.getItem("userPacks")).find(
      //           (requestobj) => requestobj.packType === "standard",
      //         );
      let showsubscibe = sessionStorage.getItem("userfeatures")&& JSON.parse(sessionStorage.getItem("userfeatures")).enableSubscribeAction && JSON.parse(sessionStorage.getItem("userfeatures")).enableSubscribeAction ;

        return (
          localStorage.getItem("Logged-in") && showsubscibe && 
          (item.name === "premium_Pwa" || item.name === "portalPremium")
        );
          // if(localStorage.getItem("Logged-in")  && sessionStorage.getItem("userPacks") != null){
          //   // let pack = JSON.parse(sessionStorage.getItem("userPacks")).find(
          //   //   (requestobj) => requestobj.packType === "standard",
          //   // );
          //   // // console.log(Object.keys(pack).length);
          //   // if(pack && Object.keys(pack).length <= 0){
          //     let pack = sessionStorage.getItem("userPacks")&& JSON.parse(sessionStorage.getItem("userPacks")).length>0 && JSON.parse(sessionStorage.getItem("userPacks")).find(
          //       (requestobj) => requestobj.packType === "standard",
          //     );
          //     console.log(!pack);
          //     if(!pack){
          //       return (  
          //         (item.name === "premium_Pwa" || item.name === "portalPremium") );
          //     }
             

          //   // }else{

          //   //   return null;
          //   // }
          // } 
      }
      return true;
    })
    .map((item, i) => {
      // console.log(item)
      const image_links = getImgsrc(item);
      // if (image_links.icon !== "") {
      //  console.log(item.title);
      if (item.layoutType !== "menu") {
        //alert(item.layoutType)
        let title = item.title;
        if (item.altTitle) {
          title = item.altTitle;
        }
           
            // if(pack && Object.keys(pack).length <= 0){

        return (
        
          (item.name === "premium_Pwa" || item.name === "portalPremium")  ? (

            <li
              key={i}
              onClick={() => {
                premiumRedirect(item.actionUrl,props.p);
              }}
            >
              <NavLink to="/" onClick={(event) => event.preventDefault()}>
                <img
                  src={image_links.icon}
                  alt=""
                  className="menuitem_icon menuitem_default_icon"
                />
                <img src={image_links.activeicon} alt='' className="menuitem_icon menuitem_highlighted_icon" /> 
                {title}
                {isMobile && (
                  <img
                    src={right_arrow}
                    alt=""
                    className="menuitem_right_arrow menuitem_right_default"
                  />
                )}
                 {isMobile && <img src={right_arrow_highlighted} alt='' className="menuitem_right_arrow menuitem_right_highlighted"/>}
                {/* <img src={right_arrow} alt='' className="menuitem_right_arrow menuitem_right_default"/>
            <img src={right_arrow_highlighted} alt='' className="menuitem_right_arrow menuitem_right_highlighted"/> */}
              </NavLink>
            </li>
          ) : (
            <li key={i}>
              <NavLink to={"/" + image_links.link} key={i} exact>
                <img
                  src={image_links.icon}
                  alt=""
                  className="menuitem_icon menuitem_default_icon"
                />
                <img src={image_links.activeicon} alt='' className="menuitem_icon menuitem_highlighted_icon" /> 
                {title}
                {isMobile && (
                  <img
                    src={right_arrow}
                    alt=""
                    className="menuitem_right_arrow menuitem_right_default"
                  />
                )}
                {isMobile && <img src={right_arrow_highlighted} alt='' className="menuitem_right_arrow menuitem_right_highlighted"/>}
                {/* <img src={right_arrow} alt='' className="menuitem_right_arrow menuitem_right_default"/>
            <img src={right_arrow_highlighted} alt='' className="menuitem_right_arrow menuitem_right_highlighted"/> */}
              </NavLink>
            </li>
          )
        );
      }
      // }
    });
};
// userLogout
// const clearCookie=(name)=> {
//   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
// }
const getSsoClientId = () => {
  myplexAPI.getSsoAppInfo().then((response) => {
    // console.log(response)
    if (response.data.code === 200 && response.data.status === "SUCCESS") {
      localStorage.setItem("client_id", response.data.app_id);
      // window.location.href = `https://id.manoramaonline.com/authorize?scope=openid+email+profile+offline_access+mobile&response_type=code+token+id_token&client_id=${response.data.app_id}&redirect_uri=${process.env.REACT_APP_DOMAINNAME}login&state=1135778640&nonce=212883085`;
      window.location.href = `${process.env.REACT_APP_SSO_URL}authorize?scope=openid+email+mobile+profile+offline_access&response_type=code&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DOMAINNAME}login&state=156414&nonce=177292&display=&prompt=`
    }
  });
};
// const userLogout=(e)=>{
//   e.preventDefault();
//   myplexAPI.logOut().then(response => {
//     console.log(response);
//     myplexAPI.ssoLogout().then(res =>{
//       console.log(res)
//     })
//     var logoutdata=[];
//     //logoutdata['user id']=localStorage.getItem('userIdLogin');
//     //firebaseEvents.logout(logoutdata);

//     sessionStorage.clear();
//     localStorage.clear();

//     localStorage.removeItem("Logged-in");

//     //lensEvents.logoutfn();

//     clearCookie("logged-in");
//     //this.Signin.current._onLogout();
//     useNavigate().push('/');
//     //this.props.history.push(`/`);
//     //cleverTap.logout({});
//     window.location.reload(true);
//   });

// }
//fetch bottom sidemenu
const _fetchBottomMenu = (props) => {
  let indexArrayNav = props.data.findIndex((item, i) => {
    return item.layoutType === "sideNavMenuSeparator";
  });
  if (indexArrayNav === -1) {
    return null;
  }
  //alert(indexArrayNav)

  // var slicecount;
  // eslint-disable-next-line
  // {isMobile ?
  //  slicecount = 5 : slicecount = 0}

  // eslint-disable-next-line array-callback-return
  return props.data.slice(indexArrayNav).map((item, i) => {
    // console.log(item)
    const image_links = getImgsrc(item);
    // if (image_links.icon !== "") {
    //  console.log(item.title);
    if (item.layoutType !== "menu") {
      let title = item.title;
      // if (item.altTitle) {
      //   title = item.altTitle;
      // }
      // console.log(title);
      return (
        //  <NavLink to={"/" + image_links.link} key={i} exact>
        //    <li>
        //      <i className="mob-icon home-icon">
        //        <img
        //            src={image_links.icon}
        //            alt={item.title}
        //            className="menu_default"
        //        />
        //        <img
        //            src={image_links.activeicon}
        //            alt={item.title}
        //            className="menu_active active "
        //        />
        //      </i>
        //      <span className="submenu-list__item">{title}</span>
        //    </li>

        //  </NavLink>
        <li key={i}>
          <NavLink to={"/" + image_links.link} key={i} exact>
            {" "}
            {title }{" "}
            {isMobile && (
              <img
                src={right_arrow}
                alt=""
                className="menuitem_right_arrow menuitem_right_default"
              />
            )}
            {isMobile && (
              <img
                src={right_arrow_highlighted}
                alt=""
                className="menuitem_right_arrow menuitem_right_highlighted"
              />
            )}
          </NavLink>
        </li>
      );
    }
    // }
  });
};
const getImgsrc = (item) => {
  let response = {
    icon: "",
    link: item.title.replace(/ /g, "-"),
    activeicon: "",
  };
  // console.log(item)
  switch (item.title) {
    case "Home":
      response.link = "";
      response.icon = about_icon_mm;
      response.activeicon = about_icon_mm;
      break;
    case "My Stuff":
      response.icon = nav_drawer_my_Stuff_icon_apex;
      response.activeicon = nav_drawer_my_Stuff_icon_apex;
      break;
    case "My Watchlist":
      response.icon = nav_drawer_watchlist_icon_apex;
      response.activeicon = nav_drawer_watchlist_icon_apex;
      break;
    case "Help":
      response.icon = nav_drawer_help_icon_apex;
      response.activeicon = nav_drawer_help_icon_apex;
      break;

    case "Movies":
      response.icon = My_Stuff_icon_mm;
      response.activeicon = My_Stuff_icon_mm;
      break;

    case "Worlds":
      response.icon = world_default;
      response.activeicon = world_selected;
      break;
    case "Originals":
      response.icon = My_Stuff_icon_mm;
      response.activeicon = My_Stuff_icon_mm;
      break;

    case "Downloads":
      response.icon = downloads_icon;
      response.activeicon = downloads_icon_highlighted;
      break;
    case "Favorites":
      response.icon = favorites_icon;
      response.activeicon = favorites_icon_highlighted;
      break;
      case "Liked Videos":
      response.icon = favorites_icon;
      response.activeicon = favorites_icon_highlighted;
      break; 
      
    case "Watch History":
      response.icon = watch_history_icon;
      response.activeicon = watch_history_icon_highlighted;
      break;
    case "Playlist":
      response.icon = playlist_icon;
      response.activeicon = playlist_icon_highlighted;
      break;
    case "Premium":
      response.icon = premium_icon;
      response.activeicon = premium_icon_highlighted;
      break;
    // case "Live TV":
    //   response.icon = live_default_icon;
    //   response.activeicon = live_highlighted_icon;
    //   break;
    // case "TV Shows":
    //   response.icon = tv_shows_default_icon;
    //   response.activeicon = tv_shows_highlighted_icon;
    //   break;
    // case "Movies":
    //   response.icon = movies_default_icon;
    //   response.activeicon = movies_highlighted_icon;
    //   break;
    // case "Kids":
    //   response.icon = kids_default_icon;
    //   response.activeicon = kids_highlighted_icon;
    //   break;
    // case "Videos":
    //   response.icon = music_default_icon;
    //   response.activeicon = music_highlighted_icon;
    //   break;
    case "About":
      response.icon = about_icon_mm;
      response.activeicon = about_icon_mm;
      break;

    case "Content Language":
      response.icon = nav_drawer_language_icon_default;
      response.activeicon = nav_drawer_language_icon_highlight;
      break;
    case "App Language":
      response.icon = nav_drawer_language_icon_default;
      response.activeicon = nav_drawer_language_icon_highlight;
      break;
    case "Languages":
      response.icon = nav_drawer_language_icon_default;
      response.activeicon = nav_drawer_language_icon_highlight;
      break;
    case "device":
      response.icon = nav_drawer_my_devices_icon_default;
      response.activeicon = nav_drawer_my_devices_icon_highlight;
      break;

    default:
      response.icon = about_icon_mm;
      response.activeicon = about_icon_mm;
  }
  // console.log(response)
  return response;
};
const togglemenu = (e) => {
  if (isMobileold) {
    document.getElementById("sidemenu").classList.toggle("open");
  }
};
const toggledropdown = (e) => {
  if (isMobileold) {
    document.getElementById("dropdown").classList.toggle("openmenu");
  }
};
const closeSideMenu = () => {
  //document.getElementById("sidemenu").classList.toggle("open");
};
const isMobileold = window.innerWidth <= 768;

const SideMenu = (props) => {
  var loginStatus =
    localStorage.getItem("Logged-in") !== undefined &&
    localStorage.getItem("Logged-in") !== null
      ? true
      : false;
  if (
    isMobileold &&
    document.getElementById("sidemenu") != null &&
    document.getElementById("sidemenu").classList.contains("open")
  ) {
    document.getElementById("sidemenu").classList.remove("open");
  }

  return (
    <div
      className="mobile_toggle"
      id="dropdown"
      onClick={(e) => toggledropdown(e)}
    >
      <ul className="nav navbar-nav select_hamburger">
        <li className="dropdown">
          <span
            className="dropdown-toggle nav-list__link"
            data-toggle="dropdown"
          >
          
          </span>

          {!isMobile && (
            <ul className="dropdown-menu">
              {_fetchMenu(props)}
              <hr></hr>
              {_fetchBottomMenu(props)}
             
            </ul>
          )}

          <ul className="nav-mobile check">
            <li className="menu-container">
              <input id="menu-toggle" type="checkbox" />
              <label htmlFor="menu-toggle" className="menu-button">
       
                <div
                  className="hamburger_menu"
                  id="sidemenu"
                  onClick={(e) => togglemenu(e)}
                >
                  <div className="trigger trigger_topheader">
                    <span className="hamburger_line" />
                  </div>
                </div>
              </label>

              <div className="menu-sidebar">
                <div className="sidemenu_header_part">
                  <div className="sidemenu_close_part">
                    <label htmlFor="menu-toggle">
                      <div
                        className="hamburger_menu"
                        id="sidemenu"
                        onClick={(e) => togglemenu(e)}
                      >
                        <img
                          src={menu_close}
                          alt=""
                          className="sidemenu_close_icon"
                        />
                      </div>
                    </label>
                  </div>
                  <div className="sidemenu_logo_part">
                    <img
                      src={brand_icon}
                      alt=""
                      className="sidemenu_brand_icon"
                    />
                  </div>
                </div>
                <hr />
                {loginStatus ? (
                  <div className="sidemenu_myaccount_part">
                    <NavLink to={"/Myaccount"}>
                      <div className="sidemenu_myaccount">
                        <img
                          src={myaccount_profile_icon}
                          alt=""
                          className="myaccount_profile"
                        />
                        <div className="sidemenu_myaccount_details">
                          <h3>My Account</h3>
                          <p>{localStorage.getItem("userName")}</p>
                        </div>
                        <img
                          src={right_arrow_highlighted}
                          alt=""
                          className="sidemenu_right_arrow"
                        />
                      </div>
                    </NavLink>
                  </div>
                ) : (
                  <div className="sidemenu_myaccount_part">
                    <NavLink
                      to={"/"}
                      onClick={(e) => {
                        e.preventDefault();
                        getSsoClientId();
                      }}
                    >
                      <div className="sidemenu_myaccount">
                        <img
                          src={myaccount_profile_icon}
                          alt=""
                          className="myaccount_profile"
                        />
                        <div className="sidemenu_myaccount_details">
                          <h3>Login</h3>
                          <p>or Create Account</p>
                        </div>
                        <img
                          src={right_arrow_highlighted}
                          alt=""
                          className="sidemenu_right_arrow"
                        />
                      </div>
                    </NavLink>
                  </div>
                )}
                <hr />

                <ul className="sidemenu_items">
                  {_fetchMenu(props)}
 
                </ul>
                <hr />
                <ul className="sidemenu_items">
                  {_fetchBottomMenu(props)}
                  
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default SideMenu;
