import React, { useState} from "react";
import { Modal } from "react-bootstrap";
import manoramalogo from "../../assets/images/manorama_max_logo_dark_bg.png";
import myplexAPI from "../../api/myplexAPI";
import {withRouter } from "react-router";
function CouponLandingPopup(props) {    
    const clickActionButton = (data) => {
        props.modalonHide();
        var url = process.env.REACT_APP_DOMAINNAME;
        if (data && data.toLowerCase === 'signin' && localStorage.getItem('Logged-in') === undefined || localStorage.getItem('Logged-in') === null) {
            console.log("url non loggedInUser : ");
            getSsoClientId();
        }else{
            //url = url+data.action;
            url = data.action;
            //window.location.href = url;
            props.history.push(url);
        }
    }
    const getSsoClientId = () => {
        myplexAPI.getSsoAppInfo().then((response) => {
            // console.log(response)
            if (response.data.code === 200 && response.data.status === "SUCCESS") {
                localStorage.setItem("client_id", response.data.app_id);
                // window.location.href = `https://id.manoramaonline.com/authorize?scope=openid+email+profile+offline_access+mobile&response_type=code+token+id_token&client_id=${response.data.app_id}&redirect_uri=${process.env.REACT_APP_DOMAINNAME}login&state=1135778640&nonce=212883085`;
                window.location.href = `${process.env.REACT_APP_SSO_URL}authorize?scope=openid+email+mobile+profile+offline_access&response_type=code&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DOMAINNAME}login&state=156414&nonce=177292&display=&prompt=`
            }
        });
    };
    // const clickCancelAutoRenewalSuccessBtn = () => {
    //     props.modalonHide();
    //     props.history.push("/");
    // }
    const showButtonsList = (buttonsData) => buttonsData.map((data,i) => {
		return (
            <button style={{ color: data?.text_color, backgroundColor : data?.bg_color }} onClick={() => clickActionButton(data)} type="button" className="subscription_btn" key={i+1}>{data?.text}</button>
        )
    });
    return (
        <Modal
            show={props.coupon_landing_popup}
            onHide={props.modalonHide}
            className="modal_infomsg mobile_signin subscription_content_alertbox coupon_landing_popup"
        >
            <Modal.Header closeButton>
                <img
                    alt="logo"
                    src={manoramalogo}
                    className="mainlogo_mobile mainlogo_dark"
                />
            </Modal.Header>
            <Modal.Body>               
                <div>
                    {props?.UIObj?.title && props?.UIObj?.title !== "" && <div className={`info_title`}><h3>{props?.UIObj?.title}</h3></div>}
                    {/* <div className="info_body" dangerouslySetInnerHTML={{ __html: `<p style='color:#2CD7F3'>Congrats! You have won a free 1-month subscription plan of manoramaMAX.</p> <ul><li>Ad-free access</li> <li> 400+ Movies & MAX Exclusives </li> <li>Early Access to Mazhavil Manorama TV shows</li></ul> <p>To avail the same, please complete your login by clicking the below link. Your subscription plan will be automatically active upon successful login/registration. </p>` }}></div> */}
                    <div className="info_body" dangerouslySetInnerHTML={{ __html: props?.UIObj?.display_text }}></div>
                    {props?.UIObj?.btns && props?.UIObj?.btns.length > 0 && 
                        <div className="subscription_alertbox cancelAutoRenewal">
                            {showButtonsList(props?.UIObj?.btns)}
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className="info_footer">
                <footer>
                </footer>
            </Modal.Footer>
        </Modal>
    );
}
export default  withRouter(CouponLandingPopup);